import { fql } from "fauna";
import { flattenTermByStatus } from "../../common/utils";

function getUniqueCoursesAndTeachersAndClassrooms(data) {
  let teachers = [];
  let classrooms = [];
  let courses = [];
  data.data.forEach(termAndCourse => {
    termAndCourse.courses.data.forEach(course => {
      if (!courses.some(c => c.value === course.data.name)) {
        courses.push({
          value: course.data.name,
          label: course.data.name,
        });
      }
      if (course.data.teacher) {
        if (!teachers.some(teacher => teacher.value === course.data.teacher.id)) {
          teachers.push({
            value: course.data.teacher.id,
            label: course.data.teacher.name,
          });
        }
      }
    });
    if (termAndCourse.term.data.classroom) {
      if (!classrooms.some(classroom => classroom.value === termAndCourse.term.data.classroom.id)) {
        classrooms.push({
          value: termAndCourse.term.data.classroom.id,
          label: termAndCourse.term.data.classroom.name,
        });
      }
    }
  })

  teachers.sort((a, b) => a.label.localeCompare(b.label))
  classrooms.sort((a, b) => a.label.localeCompare(b.label))
  courses.sort((a, b) => a.label.localeCompare(b.label))

  return {courses, teachers, classrooms};
}


export const getActiveTermV10 = async (client) => {
  const query = fql`
    Term.byStatus(1).map(arg0 => 
      { 
        term : arg0 {data, id} ,
        courses: Course.byTerm(Term(arg0.id)).pageSize(999) {data, id} 
      }
    )`
  const response = await client.query(query);
  let terms = [];
  response.data.data.forEach(({term}) => { // Added parentheses around {term}
    terms.push(term);
  });

  return {...getUniqueCoursesAndTeachersAndClassrooms(response.data), flatternCourses: flattenTermByStatus(response.data), terms: terms};
}

const exportItems = {
};

export default exportItems;
