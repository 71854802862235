import React, { createContext, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Client } from 'fauna';

const Faunav10Context = createContext({});

export const useFaunav10Context = () => {
  const context = useContext(Faunav10Context);
  if (!context) {
    throw new Error('Faunav10Context not exists');
  }
  return context;
};

const Faunav10Provider = (props) => {
  const { user } = useAuth0();
  const client = user
    ? new Client({
        secret: user['https://fauna.com/user_metadata']?.secret,
      })
    : props.client;

  const value = {
    client,
  };

  return (
    <Faunav10Context.Provider value={value} {...props}></Faunav10Context.Provider>
  );
};

export default Faunav10Provider;
