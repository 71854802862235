import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fnV10Client } from './faunadb/helpers/graphql_client';
import MyRouter from './components/router/MyRouter';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { MyIntlProvider } from './lang/MyIntlProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

// import default style
import 'rsuite/dist/rsuite.min.css';
import Faunav10Provider from './providers/FaunaV10Provider';

const queryClient = new QueryClient();

function App() {
  const { isAuthenticated, user } = useAuth0();
  const [faunaV10Client, setFaunaV10Client] = useState(fnV10Client);

  useEffect(() => {
    if (isAuthenticated) {
      toast(`🦄 Logged in as:${user.email}`);
      setFaunaV10Client(user['https://fauna.com/user_metadata'].secret);
    }
    return () => { };
    //eslint-disable-next-line
  }, [isAuthenticated]);
  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') return (<div>{process.env.REACT_APP_MAINTENANCE_MESSAGE}</div>);
  return (
    <>
      <MyIntlProvider>
        <QueryClientProvider client={queryClient}>
          <Faunav10Provider client={faunaV10Client}>
            <MyRouter />
          </Faunav10Provider>
          <ToastContainer
            autoClose={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />
        </QueryClientProvider>
      </MyIntlProvider>
    </>
  );
}

export default App;
