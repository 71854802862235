import React, { useState } from 'react';
import styled from 'styled-components';
import {
  AiOutlineHome,
  AiOutlinePoweroff,
  AiOutlineLogin,
} from 'react-icons/ai';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import {
  GiHamburgerMenu,
  GiPrivateFirstClass,
  GiBlackBook,
  GiPartyPopper,
} from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage } from 'react-intl';

const NavItemDes = styled.p`
  position: absolute;
  visibility: hidden;
  transition: opacity 0.5s ease-out;
  opacity: 0;
  color: #917a61;
`;

const HamBurgerContainer = styled.div`
  width: 75px;
  height: 75px;
  transition: width 0.5s;
  display: flex;
  justify-content: space-evenly;
  background-color: #ffffff00;
  border-top-right-radius: 30px;
  & svg {
    color: #917a61;
  }
  padding: 0;
  border: none;
  align-items: center;
  color: #fff;
  cursor: pointer;
`;
const NavItem = styled(Link)`
  width: 75px;
  height: 75px;
  transition: width 0.5s;
  text-decoration: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition: background-color 0.5s;
  :hover {
    background-color: #f2efec;
  }
  & svg {
    color: #917a61;
    height: 30px;
    width: 30px;
  }
  &:last-child {
    margin-top: auto;
  }
`;

const NavBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 75px;
  height: 100%;
  /* background-color: white; */
  transition: width 0.5s;
  transition: box-shadow 0.5s;
  display: flex;
  flex-direction: column;
  z-index: 1;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  z-index: 999;
  background: white;

  box-shadow: ${({ expand }) => (expand ? '5px 0px 5px 5px #E4DED8' : 'unset')};

  /* @media (max-width: 576px) {
    flex-direction: row;
    width: 100% !important;
    height: 75px;
    top: calc(100% - 75px);
    justify-content: space-evenly;
  } */

  & {
    width: ${(props) => (props.expand ? '150px' : '0px')};
  }
  & ${HamBurgerContainer} {
    width: ${(props) => (props.expand ? '150px' : '75px')};
  }
  & ${NavItem} {
    width: ${(props) => (props.expand ? '150px' : '0px')};
  }

  & ${NavItemDes} {
    visibility: ${(props) => (props.expand ? 'visible' : 'hidden')};
    position: ${(props) => (props.expand ? 'unset' : 'absolute')};
    opacity: ${(props) => (props.expand ? 1 : 0)};
  }
`;

const Navbar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [expand, setExpand] = useState(true);
  return (
    <NavBackground expand={expand}>
      <HamBurgerContainer onClick={() => setExpand(!expand)}>
        <GiHamburgerMenu></GiHamburgerMenu>
      </HamBurgerContainer>
      <NavItem
        to="/"
        onClick={() => (window.location = 'https://www.spinderella.com.hk')}
      >
        <AiOutlineHome />
        <NavItemDes>
          <FormattedMessage id="home" defaultMessage="Home" />
        </NavItemDes>
      </NavItem>
      <NavItem to="/">
        <GiBlackBook />
        <NavItemDes>
          <FormattedMessage id="enroll_now" defaultMessage="Enroll now" />
        </NavItemDes>
      </NavItem>
      {isAuthenticated && (
        <NavItem to="/performance">
          <GiPartyPopper />
          <NavItemDes>
            <FormattedMessage id="performance" defaultMessage="Performance" />
          </NavItemDes>
        </NavItem>)}
      {isAuthenticated && (
        <NavItem to="/me">
          <BsFillPersonLinesFill />
          <NavItemDes>
            <FormattedMessage id="me" defaultMessage="Me" />
          </NavItemDes>
        </NavItem>
      )}
      {/* {isAuthenticated && (
        <NavItem to="/order">
          <GiBlackBook />
          <NavItemDes>
            <FormattedMessage id="order" defaultMessage="Orders" />
          </NavItemDes>
        </NavItem>
      )} */}
      {isAuthenticated && (
        <NavItem to="/class">
          <GiPrivateFirstClass />
          <NavItemDes>
            <FormattedMessage id="class" defaultMessage="My Classes" />
          </NavItemDes>
        </NavItem>
      )}
      {isAuthenticated && (
        <NavItem to="/catchup">
          <GiPrivateFirstClass />
          <NavItemDes>
            <FormattedMessage
              id="catchup"
              defaultMessage="Catch up / Drop in"
            />
          </NavItemDes>
        </NavItem>
      )}
      {isAuthenticated && (
        <NavItem to="/tnc">
          <NavItemDes>
            <FormattedMessage
              id="tnc"
              defaultMessage="T&C"
            />
          </NavItemDes>
        </NavItem>
      )}
      {!isAuthenticated && (
        <NavItem
          to="/"
          onClick={(e) => {
            e.preventDefault();
            loginWithRedirect();
          }}
        >
          <AiOutlineLogin />
          <NavItemDes>
            <FormattedMessage id="login" defaultMessage="Login" />
          </NavItemDes>
        </NavItem>
      )}
      {isAuthenticated && (
        <NavItem
          to="/"
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
        >
          <AiOutlinePoweroff />
          <NavItemDes>
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </NavItemDes>
        </NavItem>
      )}
    </NavBackground>
  );
};

export default Navbar;
