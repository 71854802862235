import styled from 'styled-components';

export const TermStartDate = styled.p`
  font-weight: 400;
`;

export const TermDescriptionContainer = styled.div`
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  width: 200px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CourseCardName = styled.h2`
  color: #917a61;
  font-weight: 800;
  font-size: 1.25em;
`;

export const CourseCardBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const CourseTeacherIconContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 85px;
  clip-path: circle(50%);
`;

export const CourseWordItem = styled.p`
  margin: 5px 0px;
`;

export const CourseCardDivideLine = styled.div`
  border: 1px solid #e4e4e440;
  width: 60%;
  margin: auto;
`;

export const CourseApplyButton = styled.button``;

export const CourseCardContainer = styled.div`
  position: relative;
  max-width: 250px;
  min-width: 200px;
  min-height: 100px;
  width: 100%;
  height: auto;
  margin: 5px 0px;
  padding: 10px;
  border-radius: 30px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.16);
  transition: all 0.4s cubic-bezier(0.02, 0.01, 0.47, 1);
  color: #917a61;
  display: flex;
  justify-content: center;
  transform: translate(0, 15px);
  ${CourseCardBody} {
    margin-top: 0px;
  }
  ${TermDescriptionContainer} {
    opacity: 1;
    height: auto;
    width: 75%;
    background: white;
    border-radius: 15px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -60px);
    padding: 15px 15px;
    z-index: 0;
    display: flex;
    justify-content: center;
  }
`;
