import { fql } from 'fauna';

export const getMyProfile = async (client) => {
  const query = fql`Query.identity()`;
  const response = await client.query(query);
  return response.data;
};

export const getMyCourses = async (client) => {
  const query = fql`course_students.byStudent(Student(Query.identity()!.id)).pageSize(999){
      id: .courseID.id,
      name: .courseID.name,
      quota: .courseID.quota,
      price: .courseID.price,
      weekday: .courseID.weekday,
      start_at: .courseID.start_at,
      teacher: .courseID.teacher {
        name
      },
      term: .courseID.term {
        id,
        name,
        start_date,
        end_date,
        classroom: .classroom.name
      }
    }`;
  const response = await client.query(query);
  return response.data;
};

export const getMyOrders = async (client) => {
  const query = fql`Order.byStudent(Student(Query.identity()!.id)).pageSize(999) {
    canceled,
    price,
    date,
    course {
      id,
      name,
      quota,
      price,
      weekday,
      start_at,
      teacher {
        name
      },
      term {
        id,
        name,
        start_date,
        end_date,
        classroom: .classroom.name
      }
    }
  }`;

  const response = await client.query(query);
  return response.data;
}

export const getMyLogs = async (client) => {
  const query = fql`
    let logs = Log.all_logs_by_target(Student(Query.identity()!.id)).take(10).concat(Log.all_logs_by_source(Student(Query.identity()!.id)).take(10)){
      from,
      to,
      date,
      action,
      toCourse
    }
    logs`;
  const response = await client.query(query);
  return response.data;
}


export const updateStudentName = async (client, name, phone_number) => {
  const query = fql`
    Query.identity().coll(Query.identity().id).update({name:${name}, phone_number:${phone_number}})
  `;
  const response = await client.query(query);
  return response.data;
}

export const agreeTnc = async (client) => {
  const query = fql`
  Query.identity().coll(Query.identity().id).update({tnc_agreed: true})
  `;
  const response = await client.query(query);
  return response.data;
};

export const applyCourse = async (client, courseId) => {
  const query = fql`applyCourse(${courseId})`;
  const response = await client.query(query);
  console.log(response)
  return response.data;
};

export const getMyUpComingClass = async (client) => {
  const query = fql`
    AttendanceTicket.AttendanceTicketByStudent(Student(Query.identity().id)).pageSize(999).where(.date >= Time.now().subtract(2, "hours") && .date <= Time.now().add(180, "days")){
      ticket_id: .id,
      attended: .attended,
      catchup: .catchup,
      date: .date,
      course: .course.name,
      deleted: .course.deleted,
      classroom: .course.term.classroom.name
  }`;
  const response = await client.query(query);
  console.log(response)
  return response.data;

}

export const getActiveCourses = async (client) => {
  const query = fql`
    course_students.byStudent(Student(Query.identity().id)).where(arg0 => Term.byStatus(1).map(arg0 => Term(arg0.id)).toArray().includes(arg0.termID)) {
      name: .courseID.name,
      teacher: .courseID.teacher.name,
      weekday: .courseID.weekday,
      start_at: .courseID.start_at,
    }
  `;
  const response = await client.query(query);
  return response.data;
}

export const releaseClass = async (client, ticketId, tier_type) => {
  let t0Add = 0;
  let t1Add = 0;
  let t2Add = 0;
  let logMessage = '';
  if (tier_type === 'catch_up_credit_tier_0') {
    t0Add = 1;
    logMessage = 'release + 1 🥉';
  }
  if (tier_type === 'catch_up_credit_tier_1') {
    t1Add = 1;
    logMessage = 'release + 1 🥈';
  }
  if (tier_type === 'catch_up_credit_tier_2') {
    t2Add = 1;
    logMessage = 'release + 1 🥇';
  }

  const query = fql`
    let student = Student(Query.identity().id)
    let ticket = AttendanceTicket.byId(${ticketId})
    let term = AttendanceTicket.byId(${ticketId}).course.term
    if(Date.today().difference(term.start_date)<0){
      abort('Not in catch up period. Catch up period start after Term start.')
    }
    student.update({
      catch_up_credit_tier_0: student.catch_up_credit_tier_0 + ${t0Add},
      catch_up_credit_tier_1: student.catch_up_credit_tier_1 + ${t1Add},
      catch_up_credit_tier_2: student.catch_up_credit_tier_2 + ${t2Add},
    })
    Log.create({
      from: student,
      to: ticket.session,
      action: ${logMessage},
      date: Time.now()
    })
    ticket.update({
      student: 'None',
      attended: false,
    })
  `;
  const response = await client.query(query);
  return response.data;
}

export const fetchCatchUpClasses = async (client) => {
  const query = fql`
    AttendanceTicket.AttendanceTicketByStudent('None').pageSize(9999).where(.date>=Time.now() && .date <= Time.now().add(180,'days')) {
    ticket_id: .id,
    attended: .attended,
    date: .date,
    course: .course.name,
    teacher: .course.teacher {name},
    classroom: .course.term.classroom.name,
    deleted: .deleted,
    term: .course.term
  }
  `;
  const response = await client.query(query);
  return response.data;
}

export const getCatchUpCredits = async (client) => {
  const query = fql`
    let student = Student(Query.identity().id)
    {
      catchUpCreditTier0: student.catch_up_credit_tier_0,
      catchUpCreditTier1: student.catch_up_credit_tier_1,
      catchUpCreditTier2: student.catch_up_credit_tier_2
    }
  `;
  const response = await client.query(query);
  return response.data;
}

export const catchUpClass = async (
  client,
  ticket_id,
  credit,
  creditLevel
) => {
  let t0deduct = 0;
  let t1deduct = 0;
  let t2deduct = 0;
  let logMessage = '';
  if (creditLevel === 'catch_up_credit_tier_0') {
    t0deduct = 1;
    logMessage = `paid $${credit} and -1 🥉 to catch`;
  }
  if (creditLevel === 'catch_up_credit_tier_1') {
    t1deduct = 1;
    logMessage = `paid $${credit} and -1 🥈 to catch`;
  }
  if (creditLevel === 'catch_up_credit_tier_2') {
    t2deduct = 1;
    logMessage = `paid $${credit} and -1 🥇 to catch`;
  }
  if (creditLevel === 'catch_up_credit_tier_na') {
    logMessage = 'paid $350 catch up';
  }
  const query = fql`
      let student = Student(Query.identity().id)
      let ticket = AttendanceTicket(${ticket_id})
      let studentList = AttendanceTicket.ByClass(ticket.session){student}
      let exist = studentList.firstWhere(arg0 => arg0 == {student: Student(Query.identity().id)})
      if(exist != null){
        abort('You are in this class already.')
      }
      if(student.credit < ${credit}){
        abort('Not enough credit')
      }
      if(ticket.student != "None"){
        abort('Class is full')
      }
      student.update({
        credit: student.credit - ${credit},
        catch_up_credit_tier_0: student.catch_up_credit_tier_0 - ${t0deduct},
        catch_up_credit_tier_1: student.catch_up_credit_tier_1 - ${t1deduct},
        catch_up_credit_tier_2: student.catch_up_credit_tier_2 - ${t2deduct},
      })
      if(student.credit < 0){
        abort('Not enough credit')
      }
      if(student.catch_up_credit_tier_0 < 0){
        abort('Not enough 🥉 Catch Up Point')
      }
      if(student.catch_up_credit_tier_1 < 0){
        abort('Not enough 🥈 Catch Up Point')
      }
      if(student.catch_up_credit_tier_2 < 0){
        abort('Not enough 🥇 Catch Up Point')
      }
      Log.create({
        from: student,
        to: ticket.session,
        action: ${logMessage},
        date: Time.now()
      })
      ticket.update({
        student: student,
        catchup: true
      })
  `;
  const response = await client.query(query);
  return response.data;
};

const exportItems = {
};

export default exportItems;
