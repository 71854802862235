import { useQuery } from 'react-query';
import { useFaunav10Context } from '../../providers/FaunaV10Provider';
import { getActiveTermV10 } from '../../faunadb/queries/term';
import { CourseCardDivideLine } from '../course/styles/FilteredCourseCardStyle';
import moment from 'moment';

const TermContainer = () => {
  const { client: v10Client } = useFaunav10Context();
  const { data: activeTermV10 } = useQuery(['activeTerm', 'v10'], () =>
    getActiveTermV10(v10Client),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className='text-center'>
      <h2>Terms</h2>
      <CourseCardDivideLine />
      {activeTermV10?.terms.length > 0 && (
        <>
          <h4>{activeTermV10?.terms[0].data.name}</h4>
          <p>
            {moment(activeTermV10?.terms[0]?.data.start_date.dateString).format(
              'll'
            )}{' '}
            -{' '}
            {moment(activeTermV10?.terms[0]?.data.end_date.dateString).format(
              'll'
            )}
          </p>
          <CourseCardDivideLine />
        </>
      )}
    </div>
  );
};

export default TermContainer;