import Swal from 'sweetalert2';
import { Card, CardContent, CardHeader } from '../../../components/ui/card';
import { useMutation, useQueryClient } from 'react-query';
import { insertStudentToPerformance } from '../../../faunadb/queries/performance';
import useMyPerformancesQuery from '../hooks/useMyPerformances';
import { useFaunav10Context } from '../../../providers/FaunaV10Provider';

function ButtonText(full = false, applied = false) {
    if (applied) {
        return 'Applied'
    }
    if (full) {
        return 'Full'
    }
    return 'Apply'

}


export default function PerformanceCard({ performance }) {
    const { client: clientV10} = useFaunav10Context();
    const queryClient = useQueryClient();
    const myPerformances = useMyPerformancesQuery();
    let applied = false
    if (myPerformances?.isSuccess) {
        if (myPerformances?.data?.data?.find(item => item?.performanceID.id === performance?.id)) {
            applied = true
        }
    }

    const applyPerformanceMutation = useMutation(insertStudentToPerformance, {
        onError: (error) => {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${error?.abort}`,
            })
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(['performance_term']);
            queryClient.invalidateQueries(['my_performances']);
            queryClient.invalidateQueries(['my_profile']);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: `${data?.message}`,
            })
        },
    });
    return (
        <>
            <Card className="w-4/5 px-8 mx-auto my-5 ">
                <CardHeader className="text-spin font-extrabold text-center">
                    Performance: {performance?.performance_name}
                </CardHeader>
                <CardContent className="text-spin text-center">
                    Remaining Seats: ({performance?.quota})
                </CardContent>
                <div className="grid w-full place-items-center my-4">
                    <button
                        style={{
                            backgroundColor: (performance?.quota <= 0 || applied) && 'gray',
                        }}
                        disabled={performance?.quota <= 0 || applied}
                        className={
                            performance?.quota <= 0 || applied
                                ? 'px-11 py-2 bg-gray-500 rounded-full text-white'
                                : 'px-11 py-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-full text-white'
                        }
                        onClick={() => {
                            Swal.queue([
                                {
                                    title: `Performance: ${performance?.performance_name}`,
                                    confirmButtonText: 'Apply',
                                    showCancelButton: true,
                                    icon: 'question',
                                    text: `First performance $50, $0 thereafter.`,
                                    footer: "1. You may only perform in either the White or Grey room. You cannot perform in both during a single day.<br><br>2. If you have mistakenly enrolled in both classrooms in a single day, you must choose to perform in only one.<br><br>3. Performances are not subject to refund, deferral or transfer under any circumstances. <br><br>4. All final decisions are reserved by Spinderella Pole Studio.",
                                    showLoaderOnConfirm: true,
                                    preConfirm: () => {
                                        applyPerformanceMutation.mutate({
                                            client: clientV10,
                                            performanceId:
                                                performance.id,
                                        });
                                        return;
                                    },
                                },
                            ]);
                        }}
                    >
                        {ButtonText(performance?.quota <= 0, applied)}
                    </button>
                </div>
            </Card>
        </>
    );
}
