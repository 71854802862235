import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import styled from 'styled-components';
import {
  selectedOptionToLabelArray,
  weekdayOptions,
} from '../../common/utils';
import ApplicationTime from '../../components/application/ApplicationTime';
import FilteredCourseList from '../../components/course/FilteredCourseList';
import { getActiveTermV10 } from '../../faunadb/queries/term';
import {
  AiFillStar,
  AiOutlineStar,
  AiFillCheckCircle,
  AiOutlineCheckCircle,
} from 'react-icons/ai';
import { useFaunav10Context } from '../../providers/FaunaV10Provider';
import { useQuery } from 'react-query';

const FilterRow = styled.div`
  display: flex;
  & > div {
    flex: 0.333;
    margin: 0px 5px;
    @media (max-width: 576px) {
      margin: 5px 0px;
    }
  }
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const getFav = (id) => {
  const list = JSON.parse(localStorage.getItem('fav-list')) || [];
  if (list.indexOf(id) > -1) {
    return true;
  } else return false;
};

const HomePage = () => {
  const [weekdayFilter, setWeekdayFilter] = useState([]);
  const [teacherFilter, setTeacherFilter] = useState([]);
  const [classroomFilter, setClassroomFilter] = useState([]);
  const [courseNameFilter, setCourseNameFilter] = useState([]);
  const [courseNameOptions, setCourseNameOptions] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [classroomOptions, setClassroomOptions] = useState([]);
  const [filteredCourse, setFilteredCourse] = useState([]);
  const [courses, setCourses] = useState([]);
  const [showFavOnly, setShowFavOnly] = useState(false);
  const [showAvailableOnly, setShowAvailableOnly] = useState(false);
  
  const {client: v10Client} = useFaunav10Context();
  
  const{data} = useQuery(['activeTerm','v10'],()=>getActiveTermV10(v10Client), {
      retryDelay: 5000,
      retryOnMount:false,
      retry:1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setClassroomOptions(data?.classrooms || []);
    setTeacherOptions(data?.teachers || []);
    setCourseNameOptions(data?.courses || []);
    setCourses(data?.flatternCourses || []);
    return () => {};
  }, [data])

  useEffect(() => {
    let temp = courses.filter(function (a) {
      let classroomFilterPass = true;
      let teacherFilterPass = true;
      let weekdayFilterPass = true;
      let courseNameFilterPass = true;
      let show = true;
      let showAvailableOnlyPass = true;
      let deleted = !a.courseDeleted;
      if (classroomFilter.length > 0)
        classroomFilterPass = classroomFilter.includes(a.classroomName);
      if (teacherFilter.length > 0)
        teacherFilterPass = teacherFilter.includes(a.teacherName);
      if (weekdayFilter.length > 0)
        weekdayFilterPass = weekdayFilter.includes(a.courseWeekday);
      if (courseNameFilter.length > 0)
        courseNameFilterPass = courseNameFilter.includes(a.courseName);
      if (showFavOnly) {
        show = getFav(`c${a.courseId}`);
      }
      if (showAvailableOnly) {
        showAvailableOnlyPass = a?.courseQuota > 0;
      }
      return (
        classroomFilterPass &&
        teacherFilterPass &&
        weekdayFilterPass &&
        courseNameFilterPass &&
        show &&
        deleted &&
        showAvailableOnlyPass
      );
    });
    setFilteredCourse(temp);
    return () => {};
  }, [
    courseNameFilter,
    weekdayFilter,
    teacherFilter,
    classroomFilter,
    courses,
    showFavOnly,
    showAvailableOnly,
  ]);
  return (
    <>
      {/* <Announcement /> */}
      <ApplicationTime/>
      <div className="flex w-100 justify-evenly">
        <div
          className="flex w-100 justify-center cursor-pointer"
          style={{ color: '#91761a' }}
          onClick={() => setShowFavOnly(!showFavOnly)}
        >
          {showFavOnly ? <AiFillStar size={20} /> : <AiOutlineStar size={20} />}
          Show Star Only
        </div>
        <div
          className="flex w-100 justify-center cursor-pointer"
          style={{ color: '#91761a' }}
          onClick={() => setShowAvailableOnly(!showAvailableOnly)}
        >
          {showAvailableOnly ? (
            <AiFillCheckCircle size={20} />
          ) : (
            <AiOutlineCheckCircle size={20} />
          )}
          Show Available Only
        </div>
      </div>
      <FilterRow>
        <Select
          options={classroomOptions}
          isSearchable={false}
          isMulti
          // closeMenuOnSelect={false}
          onChange={(selected) =>
            setClassroomFilter(selectedOptionToLabelArray(selected))
          }
          placeholder={
            <FormattedMessage
              id="CLASSROOM_PLACEHOLDER"
              defaultMessage="Classroom"
            />
          }
        />
        <Select
          options={teacherOptions}
          isSearchable={false}
          isMulti
          // closeMenuOnSelect={false}
          onChange={(selected) =>
            setTeacherFilter(selectedOptionToLabelArray(selected))
          }
          placeholder={
            <FormattedMessage
              id="TEACHER_PLACEHOLDER"
              defaultMessage="Teacher"
            />
          }
        />
        <Select
          options={weekdayOptions}
          isSearchable={false}
          isMulti
          // closeMenuOnSelect={false}
          onChange={(selected) =>
            setWeekdayFilter(selectedOptionToLabelArray(selected))
          }
          placeholder={
            <FormattedMessage
              id="WEEKDAY_PLACEHOLDER"
              defaultMessage="Monday... Tues.."
            />
          }
        />
        <Select
          options={courseNameOptions}
          isSearchable={false}
          isMulti
          // closeMenuOnSelect={false}
          onChange={(selected) =>
            setCourseNameFilter(selectedOptionToLabelArray(selected))
          }
          placeholder={
            <FormattedMessage
              id="COURSE_NAME_PLACEHOLDER"
              defaultMessage="Silky.... Intro..."
            />
          }
        />
      </FilterRow>

      <FilteredCourseList courses={filteredCourse} showFavOnly={showFavOnly}/>
      <div style={{ height: '100px' }}></div>
    </>
  );
};

export default HomePage;
