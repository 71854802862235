import styled from 'styled-components';
import { CourseCardDivideLine } from '../../../components/course/styles/FilteredCourseCardStyle';
import moment from 'moment';
const Container = styled.div`
  max-width: 450px;
  min-width: 350px;
  padding: 10px 20px;
  margin: 10px 0px 30px 0px;
  background: white;
  border-radius: 30px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.16);
  color: #917a61;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & h2 {
    font-weight: 700;
    font-size: 1.5em;
  }
  & h4 {
    font-weight: 500;
    font-size: 1em;
  }
`;
export default function PerformanceTermCard({ performanceTerm }) {
  return (
    <>
      <div className="flex justify-center w-full">
        <Container>
          <h2>{performanceTerm?.performanceTerm?.data?.name}</h2>
          <CourseCardDivideLine />
          <h4>Application Period</h4>
          <p>{moment(performanceTerm?.performanceTerm?.application_start_date?.isoString).local().format('lll')} - {moment(performanceTerm?.performanceTerm?.application_end_date?.isoString).local().format('lll')}</p>
          <CourseCardDivideLine />
          <p className='mt-4'>You may only perform in either the</p>
          <p className='mt-0'>White or Grey room</p>
          <p className='mt-0'>$50 per student per day, max 3</p>
          <p className='mt-0'>performing pieces</p>
        </Container>
      </div>
    </>
  );
}
