const TncPage = () => {
    return (
        <div className="p-4" style={{ color: "rgb(145, 122, 97)" }}>
            <h1 className="text-2xl font-bold mb-4">Agreement and Release</h1>
            <p className="mb-2">I confirm that I am over 18 years old and/or have the capacity to enter into this Agreement.</p>
            <p className="mb-2">In consideration of participating in the courses, workshops, self-practice sessions and/or events (collectively, “Courses”) hosted or offered by Spinderella Pole Studio (“the Studio”) at the fees currently charged by the Studio, I confirm and acknowledge that I release the Studio from the following legal risks and responsibilities and waive all my right in relation thereto.</p>
            <h2 className="text-xl font-bold mt-4 mb-2">Risk of Pole Dancing</h2>
            <ul className="list-disc list-inside mb-4">
                <li>Pole dancing can be a dangerous sport. There is an apparent risk of suffering from various degrees of injury, including bruises, muscle strain, fractured bones, paralysis and death.</li>
                <li>It is my responsibility and I am advised to exercise my own judgment or consult health professionals as to whether I am suitable for this sport in case of any doubt. I understand that under certain physical conditions (e.g. after alcohol consumption, pregnancy, heart conditions, before and after surgery, etc.), I should not participate in this sport.</li>
                <li>I confirm that I am in good health and am physically suitable to participate in the Courses.</li>
                <li>In view of paragraph 2, the Studio bears no legal obligation and has no liability to advise me on whether my health condition or physical fitness is suitable for participating in the Course. I agree not to hold the Studio liable for any injury suffered by me as a result of taking part in the course.</li>
            </ul>
            <h2 className="text-xl font-bold mt-4 mb-2">Property</h2>
            <ul className="list-disc list-inside mb-4">
                <li className="mb-2">The Studio has no legal obligation to secure and protect my properties brought into the Studio premises. In the event that my properties have been stolen or damaged at the Studio premises, I agree not to hold the Studio liable for my loss and damage.</li>
            </ul>

            <h2 className="text-xl font-bold mt-4 mb-2">Third Party Rights</h2>
            <ul className="list-disc list-inside mb-4">
                <li className="mb-2">This Agreement does not give any rights to any third party.</li>
            </ul>

            <h2 className="text-xl font-bold mt-4 mb-2">Exclusion of Liability</h2>
            <ul className="list-disc list-inside mb-4">
                <li className="mb-2">Unless the instructors, agents and/or employees of the Studio are in wilful default or commit fraud, I acknowledge that they do not incur any personal liability in relation to or arising from the Courses, and agree not to take any legal action against them personally.</li>
            </ul>

            <h2 className="text-xl font-bold mt-4 mb-2">Governing law</h2>
            <ul className="list-disc list-inside mb-4">
                <li className="mb-2">This Agreement is governed and interpreted by the laws of the Hong Kong Special Administrative Region.</li>
            </ul>

            <h2 className="text-xl font-bold mt-4 mb-2">Severability</h2>
            <ul className="list-disc list-inside mb-4">
                <li className="mb-2">Each of the provisions of this Agreement is severable.</li>
            </ul>

            <h2 className="text-xl font-bold mt-4 mb-2">Miscellaneous</h2>
            <ul className="list-disc list-inside mb-4">
                <li className="mb-2">This Agreement is valid and legally binding between the Studio and me whenever I participate in any of the Courses.</li>
                <li className="mb-2">I understand the Studio has the absolute discretion to amend this Agreement by giving at least 1 month’s notice. In the event that I do not agree to any amendment, I acknowledge and agree that I am not entitled to participate in any of the Courses.</li>
                <li className="mb-2">I fully understand the legal consequences of this Agreement. I am advised to seek my independent legal advice before signing this Agreement. I understand and agree that it is my sole responsibility to understand this Agreement before I participate in any of the Courses.</li>
            </ul>
        </div>
    );
};

export default TncPage;