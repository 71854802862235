import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import styled from 'styled-components';
import { getMyProfile } from '../../faunadb/queries/student';
import OrderPage from '../order/OrderPage';
import MyLogs from './components/MyLogs';
import Profile from './components/Profile';
import { useQuery } from 'react-query';
import { useFaunav10Context } from '../../providers/FaunaV10Provider';

const Container = styled.div`
  max-width: 450px;
  min-width: 350px;
  padding: 30px 20px 30px 20px;
  margin: 10px 15px 30px 15px;
  background: white;
  border-radius: 30px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.16);
  color: #917a61;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & h2 {
    font-weight: 700;
    font-size: 1.5em;
  }
  & h4 {
    font-weight: 500;
    font-size: 1em;
  }
`;

const ProfilePage = () => {
  const { isAuthenticated, user } = useAuth0();

  let profile;

  const {client:v10Client} = useFaunav10Context();

  const {data, loading} = useQuery(
    ['my_profile'],
    () => getMyProfile(v10Client),{
      retryDelay: 5000,
      retryOnMount:false,
      retry:1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  if (!isAuthenticated) return <></>;
  
  if (!loading && data) {
    profile = data;
  }

  return (
    <>
      <div className="flex justify-center flex-wrap flex-col items-center">
        <Container>
          {profile && <Profile profile={profile} user={user}></Profile>}
        </Container>
        <OrderPage />
        <MyLogs />
        <div className="h-32"></div>
      </div>
    </>
  );
};

export default ProfilePage;
