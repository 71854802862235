import { useQuery } from 'react-query';
import { getMyUpComingClass } from '../faunadb/queries/student';
import { useFaunav10Context } from '../providers/FaunaV10Provider';

const useMyComingClassHook = () => {
  const {client} = useFaunav10Context();
  return useQuery(
    ['my_coming_classes'],
    async () => await getMyUpComingClass(client)
  );
};

export default useMyComingClassHook;
