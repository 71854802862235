import React, { createContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import en from './en';
import jp from './jp';
import zh from './zh';

// These are aligned with API back-end.
// yue-Hant-HK matches Google Speech API.
const languageCodes = {
  'en-US': { english: 'English (United States)' },
  'ja-JP': { english: 'Japanese' },
  'yue-Hant-HK': { english: 'Cantonese (Traditional, Hong Kong SAR China)' },
  'zh-CN': { english: 'Chinese (Mandarin, Simplified, China)' }
};

export const getLanguageDescription = (langCode, english = true) => {
  try {
    return english ? languageCodes[langCode].english : languageCodes[langCode].native;
  } catch {
    return 'Undefined language.';
  }
};

export const IntlContext = createContext({
  locale: 'en',
  setLocale: () => {}
});

const messages = {
  ...en,
  ...jp,
  ...zh
};

export const MyIntlProvider = ({ children }) => {
  const [locale, setLocale] = useState('en');
  return (
    <IntlContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
