import { useQuery } from 'react-query';
import { getMyLogs } from '../../../faunadb/queries/student';
import { useFaunav10Context } from '../../../providers/FaunaV10Provider';

const useMyLogs = () => {
  const {client} = useFaunav10Context();
  return useQuery(
    ['get_my_latest_logs'],
    () => getMyLogs(client),
    {
      retryDelay: 5000,
      retryOnMount:false,
      retry:1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export default useMyLogs;
