import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import HomePage from '../../pages/home/HomePage';
import Navbar from '../navbar/NavBar';
import { BackgroundContainer } from '../../styles/common/BackgroundContainer';
import ProfilePage from '../../pages/profile/ProfilePage';
import { useAuth0 } from '@auth0/auth0-react';
import ClassPage from '../../pages/class/ClassPage';
import MyClassPage from '../../pages/class/MyClassPage';
import OrderPage from '../../pages/order/OrderPage';
import PerformancePage from '../../pages/performance/index';
import TncPage from '../../pages/tnc/TncPage';

const MyRouter = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <Router>
      <Navbar></Navbar>
      <Switch>
        <BackgroundContainer>
          <Route path="/" exact component={HomePage} />
          {isAuthenticated && (
            <Route path="/me" exact component={ProfilePage} />
          )}
          {isAuthenticated && (
            <Route path="/catchup" exact component={ClassPage} />
          )}
          {isAuthenticated && (
            <Route path="/class" exact component={MyClassPage} />
          )}
          {isAuthenticated && (
            <Route path="/order" exact component={OrderPage} />
          )}


          {isAuthenticated && (
            <Route path="/performance" exact component={PerformancePage} />
          )
          }

          {isAuthenticated && (
            <Route path="/tnc" exact component={TncPage} />
          )
          }
        </BackgroundContainer>
      </Switch>
    </Router>
  );
};

export default MyRouter;
