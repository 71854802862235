import React from "react";
import { weekdayArray } from "../../common/utils";
import FilteredCourseCard from "./FilteredCourseCard";

const FilteredCourseList = ({ courses, showFavOnly }) => {
  return (
    <>
      <div className="flex margin-b-30">
        {weekdayArray.map((day) => (
          <ShowByWeekday
            key={`weekday-${day}`}
            title={day}
            courses={filterCourseWithWeekday(courses, day)}
            showFavOnly={showFavOnly}
          />
        ))}
      </div>
    </>
  );
};

function filterCourseWithWeekday(courses, day) {
  let result = [];
  courses.forEach((course) => {
    if (weekdayArray[course.courseWeekday - 1] === day) {
      result.push(course);
    }
  });
  result.sort((a, b) => {
    if (
      parseInt(a.courseStartAt.split(":")[0]) -
        parseInt(b.courseStartAt.split(":")[0]) ===
      0
    ) {
      return (
        parseInt(a.courseStartAt.split(":")[1]) -
        parseInt(b.courseStartAt.split(":")[1])
      );
    }
    return (
      parseInt(a.courseStartAt.split(":")[0]) -
      parseInt(b.courseStartAt.split(":")[0])
    );
  });
  return result;
}

const ShowByWeekday = ({ title, courses, showFavOnly }) => {
  return (
    <>
      {courses.length > 0 && (
        <div className="flex flex-col mx-1">
          <h2
            style={{
              width: "180px",
              textAlign: "center",
              fontWeight: "500",
              fontSize: "1em",
              marginTop: "10px",
              color: "#91761a",
            }}
          >
            {title}
          </h2>
          {courses.map((course) => (
            <FilteredCourseCard
              course={course}
              key={course.courseId}
              showFavOnly={showFavOnly}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default FilteredCourseList;
