import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Checkout = ({ payload }) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async (event) => {
    // Get Stripe.js instance
    if (payload.quantity <= 10) {
      Swal.fire({
        title: `Every top up should at least $10`,
        footer:
          `There is no expiratory date on credits. Credits are for class enrollment only and are non-transferable.
          If you require a refund, there shall be a 5% surcharge on your remaining credits under ALL circumstances.`,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        title: `Top up $${payload.quantity}`,
        footer:
          `There is no expiratory date on credits. Credits are for class enrollment only and are non-transferable.
          If you require a refund, there shall be a 5% surcharge on your remaining credits under ALL circumstances.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confrim",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const stripe = await stripePromise;

          // Call your backend to create the Checkout Session
          const response = await fetch(
            process.env.REACT_APP_STRIPE_CHECKOUT_URL,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
              method: "POST",
            }
          );

          const session = await response.json();
          setLoading(false);
          const result = await stripe.redirectToCheckout({
            sessionId: session.id,
          });

          if (result.error) {
            toast.error(result.error.message);
          }
        }
      });
    }
  };

  return (
    <button
      onClick={handleClick}
      className="px-11 py-2 bg-gray-400 hover:bg-gray-500 rounded-full text-white"
    >
      {!loading ? "Topup" : "Loading"}
    </button>
  );
};

export default Checkout;
