import { fql } from 'fauna';

//v10
export const getActivePerformanceTerm = async (client) => {
    const query = fql`
        Performance.byDeleted(false).where(.performance_term.deleted == false && .performance_term.show_on_dashboard == true).pageSize(999) {id, data}
    `;
    const response = await client.query(query);
    const data = response?.data;
    const performanceTerm = data.data[0]?.data.performance_term;
    const performanceDays = [];

    data.data.forEach(performance => {
        const performance_day = performance.data.performance_day;
        let performance_day_exists = performanceDays.some(existing_performance_day => existing_performance_day.performance_day.id === performance_day.id);
        if (!performance_day_exists) {
            performanceDays.push({ performance_day, performances: [] });
        }
    });

    data.data.forEach(performance => {
        const performance_day = performance.data.performance_day;
        performanceDays.forEach(existing_performance_day => {
            if (existing_performance_day.performance_day.id === performance_day.id) {
                existing_performance_day.performances.push({ id: performance.id, ...performance.data });
            }
        });
    });

    return { performanceTerm , performanceDays};
}

export const getMyPerformances = async (client) => {
    const query = fql`performance_performers.byStudent(Student(Query.identity()!.id)).pageSize(9999)`;
    const response = await client.query(query);
    return response.data;
}

export const insertStudentToPerformance = async ({client, performanceId}) => {
    const query = fql`insertStudentToPerformance(${performanceId})`;
    const response = await client.query(query);
    return response.data;
}