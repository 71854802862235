import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  FilterRow,
  selectedOptionToLabelArray,
  weekdayOptions,
} from '../../common/utils';
import CatchUpSummary from './components/CatchUpSummary';
import { signal } from '@preact/signals-react';
import moment from 'moment';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import TermContainer from '../../components/application/TermContainer';
import { useFaunav10Context } from '../../providers/FaunaV10Provider';
import { fetchCatchUpClasses, getCatchUpCredits } from '../../faunadb/queries/student';

const Container = styled.div`
  max-width: 450px;
  min-width: 350px;
  padding: 10px 20px;
  margin: 10px 0px 10px 0px;
  background: white;
  border-radius: 30px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.16);
  color: #917a61;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & h2 {
    font-weight: 700;
    font-size: 1.5em;
  }
  & h4 {
    font-weight: 500;
    font-size: 1em;
  }
`;

const classroomFilter = signal([]);
const courseFilter = signal([]);
const teacherFilter = signal([]);
const weekdayFilter = signal([]);

const ClassPage = () => {
  const [catchupClasses, setCatchupClasses] = useState([]);
  const [catchUpCredits, setCatchUpCredits] = useState({
    catchUpCreditTier0: 0,
    catchUpCreditTier1: 0,
    catchUpCreditTier2: 0,
  });
  const { client: clientV10 } = useFaunav10Context();

  const fetch_catch_up_classes = () => {
    fetchCatchUpClasses(clientV10)
      .then((value) => setCatchupClasses(value?.data || []));
  };
  const get_catch_up_credits = () => {
    getCatchUpCredits(clientV10)
      .then((value) => setCatchUpCredits(value));
  };
  const refresh = () => {
    fetch_catch_up_classes();
    get_catch_up_credits();
  };

  useEffect(() => {
    const fetch_catch_up_classes = () => {
      fetchCatchUpClasses(clientV10)
        .then((value) => setCatchupClasses(value?.data || []));
    };
    const get_catch_up_credits = () => {
      getCatchUpCredits(clientV10)
        .then((value) => setCatchUpCredits(value));
    };
    const refresh = () => {
      fetch_catch_up_classes();
      get_catch_up_credits();
    };
    refresh();
    return () => { };
  }, [clientV10]);

  const uniqueClassrooms = [
    ...new Set(catchupClasses.map((obj) => obj.classroom)),
  ].map((classroom) => ({ label: classroom, value: classroom }));
  const uniqueCourses = [
    ...new Set(catchupClasses.map((obj) => obj.course)),
  ].map((course) => ({ label: course, value: course }));
  const uniqueTeachers = [
    ...new Set(catchupClasses.map((obj) => obj?.teacher?.name)),
  ].map((teacher) => ({ label: teacher, value: teacher }));
  const filteredCatchUpClasses = catchupClasses.filter((c) => {
    let result = true;
    if (classroomFilter.value.length > 0) {
      result = result && classroomFilter.value.includes(c.classroom);
    }
    if (teacherFilter.value.length > 0) {
      result = result && teacherFilter.value.includes(c.teacher.name);
    }
    if (courseFilter.value.length > 0) {
      result = result && courseFilter.value.includes(c.course);
    }

    if (weekdayFilter.value.length > 0) {
      result =
        result && weekdayFilter.value.includes(moment(c.date.isoString).weekday());
    }

    return result;
  });
  return (
    <>
      <div className="mb-4">
        <div className="flex flex-col justify-center items-center w-full">
          <Container>
            <TermContainer />
          </Container>
        </div>
        <div className="flex justify-center w-full">
          <Container>
            <h2>Available Classes for Catch Up</h2>
            <p>🥉Bronze Catch Up Point : {catchUpCredits.catchUpCreditTier0}</p>
            <p>🥈Silver Catch Up Point : {catchUpCredits.catchUpCreditTier1}</p>
            <p>🥇Gold Catch Up Point : {catchUpCredits.catchUpCreditTier2}</p>
            <p>Point will be expired after this term ends.</p>
          </Container>
        </div>
        <FilterRow>
          <Select
            options={uniqueClassrooms}
            isSearchable={false}
            isMulti
            onChange={(selected) => {
              classroomFilter.value = selectedOptionToLabelArray(selected);
            }}
            placeholder={
              <FormattedMessage
                id="CLASSROOM_PLACEHOLDER"
                defaultMessage="Classroom"
              />
            }
          />
          <Select
            options={uniqueTeachers}
            isSearchable={false}
            isMulti
            onChange={(selected) => {
              teacherFilter.value = selectedOptionToLabelArray(selected);
            }}
            placeholder={
              <FormattedMessage
                id="TEACHER_PLACEHOLDER"
                defaultMessage="Teacher"
              />
            }
          />
          <Select
            options={weekdayOptions}
            isSearchable={false}
            isMulti
            onChange={(selected) => {
              weekdayFilter.value = selectedOptionToLabelArray(selected);
            }}
            placeholder={
              <FormattedMessage
                id="WEEKDAY_PLACEHOLDER"
                defaultMessage="Weekday"
              />
            }
          />
          <Select
            options={uniqueCourses}
            isSearchable={false}
            isMulti
            onChange={(selected) => {
              courseFilter.value = selectedOptionToLabelArray(selected);
            }}
            placeholder={
              <FormattedMessage
                id="COURSE_NAME_PLACEHOLDER"
                defaultMessage="Course"
              />
            }
          />
        </FilterRow>
        <CatchUpSummary
          catchupClasses={filteredCatchUpClasses}
          fetch={refresh}
          catchUpCreditTier0={catchUpCredits.catchUpCreditTier0}
          catchUpCreditTier1={catchUpCredits.catchUpCreditTier1}
          catchUpCreditTier2={catchUpCredits.catchUpCreditTier2}
        />
      </div>
    </>
  );
};

export default ClassPage;
