import React from 'react';
import styled from 'styled-components';
import { getGeneralSettingV10 } from '../../faunadb/queries/setting';
import { CourseCardDivideLine } from '../course/styles/FilteredCourseCardStyle';
import moment from 'moment';
import TermContainer from './TermContainer';
import { useFaunav10Context } from '../../providers/FaunaV10Provider';
import { useQuery } from 'react-query';

const Container = styled.div`
  max-width: 450px;
  min-width: 350px;
  padding: 10px 20px;
  margin: 10px 0px 30px 0px;
  background: white;
  border-radius: 30px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.16);
  color: #917a61;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & h2 {
    font-weight: 700;
    font-size: 1.5em;
  }
  & h4 {
    font-weight: 500;
    font-size: 1em;
  }
`;
const ApplicationTime = ({ terms = [] }) => {
  const {client: clientV10} = useFaunav10Context();
  const {loading, data} = useQuery('getGeneralSetting', ()=> getGeneralSettingV10(clientV10),
  {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  return (
    <>
      <div className="flex justify-center w-full">
        <Container>
          <h2>Application Period</h2>
          <CourseCardDivideLine />
          <h4>VIP</h4>
          {!loading && data && (
            <p>
              {moment(data.vip_apply_start_date?.isoString).format(
                'lll'
              )}{' '}
              ~{' '}
              {moment(data.vip_apply_end_date?.isoString).format(
                'lll'
              )}
            </p>
          )}
          <CourseCardDivideLine />
          <h4>General</h4>
          {!loading && data && (
            <p>
              {moment(
                data.normal_apply_start_date?.isoString
              ).format('lll')}{' '}
              ~{' '}
              {moment(data.normal_apply_end_date?.isoString).format(
                'lll'
              )}
            </p>
          )}
          <CourseCardDivideLine />
          <CourseCardDivideLine />
          <CourseCardDivideLine />

          <TermContainer/>
        </Container>
      </div>
    </>
  );
};

export default ApplicationTime;
