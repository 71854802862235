import styled from "styled-components";

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  margin: 0px 150px 0px 150px;
  height: 100%;
  width: calc(100% - 300px);
  padding: 25px;
  transition: width 0.5s;

  @media (max-width: 576px) {
    margin: unset;
    width: 100%;
  }
`;
