import moment from "moment";
import { Card, CardHeader, CardTitle, CardContent } from "../../../components/ui/card";
import { groupByClassroom } from "../../../common/utils";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tab";
import PerformanceCard from "./performance-card";
import { CourseCardDivideLine } from "../../../components/course/styles/FilteredCourseCardStyle";
import { useState } from "react";
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi'

export default function PerformanceDayCard({ performanceDay }) {
    const groupedPerformances = groupByClassroom(performanceDay?.performances) || [];
    const [triggeredRoom, setTriggeredRoom] = useState("");
    return <>
        <Card className="w-full mb-32">
            <CardHeader>
                <CardTitle className="text-spin text-center">{moment(performanceDay.performance_day.performance_date.isoString).local().format('DD/MM dddd')}</CardTitle>
                {/* <CardDescription>Card Description</CardDescription> */}
                <CourseCardDivideLine />

            </CardHeader>
            <CardContent>
                <p className="text-spin text-center font-medium">Rehearsal: {moment(performanceDay.performance_day.rehearsal_date.isoString).local().format('HH:mm')}</p>
                <p className="text-spin text-center font-medium">Guest Entrance: {moment(performanceDay.performance_day.guest_entrance_date.isoString).local().format('HH:mm')}</p>
                <p className="text-spin text-center font-medium">Performance: {moment(performanceDay.performance_day.performance_date.isoString).local().format('HH:mm')}</p>
            </CardContent>
            <Tabs onValueChange={(e) => { setTriggeredRoom(e) }}>
                <TabsList className="text-spin grid w-full grid-cols-2">
                    {Object.keys(groupedPerformances).map((key) => {
                        return <TabsTrigger key={`tab-trigger-${performanceDay.id}-${key}`} value={key}>{key} ({`${groupedPerformances[key].length}`}) {triggeredRoom === key ? <FiChevronsDown size="1.5em" /> : <FiChevronsUp size="1.5em" />}</TabsTrigger>
                    }
                    )}

                </TabsList>

                {Object.keys(groupedPerformances).map((key) => {
                    return <TabsContent key={`tab-content-${performanceDay?.performance_day?.ref?.value?.id}-${key}`} value={key}>
                        {groupedPerformances[key].map((performance) => <PerformanceCard key={performance?.id} performance={performance} />)}
                    </TabsContent>
                }
                )}
            </Tabs>
        </Card>
    </>
}