
import PerformanceTermCard from "./components/performance-term-card";
import usePerformanceTerm from "./hooks/usePerformanceTerm";
import PerformanceDayCard from "./components/performance-day-card";
import useMyPerformancesQuery from "./hooks/useMyPerformances";
import { useFaunav10Context } from "../../providers/FaunaV10Provider";

export default function PerformancePage() {
    const { client } = useFaunav10Context();
    const { data } = usePerformanceTerm(client);

    useMyPerformancesQuery();
    return <>
        <PerformanceTermCard performanceTerm={data} />
        <div className="grid place-items-start w-full lg:grid-cols-2 grid-cols-1 gap-4">
            {data?.performanceDays.sort((a, b) => a.performance_day.ts - b.performance_day.ts).map(((performanceDay) => <PerformanceDayCard key={`${performanceDay.performance_day.id}`} performanceDay={performanceDay} />))}
        </div>
    </>
}