import { useQuery } from 'react-query';
import { getActiveCourses } from '../faunadb/queries/student';
import { useFaunav10Context } from '../providers/FaunaV10Provider';

const useActiveEnrolledCourseHook = () => {
  const {client} = useFaunav10Context();
  return useQuery(
    ['my_active_enrolled_course'],
    async () => await getActiveCourses(client)
  );
};

export default useActiveEnrolledCourseHook;
