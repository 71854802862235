import { getMyOrders } from '../../../faunadb/queries/student';
import { useQuery } from 'react-query';
import { useFaunav10Context } from '../../../providers/FaunaV10Provider';

const useMyOrderHook = () => {
  const {client: v10Client} = useFaunav10Context();
  return useQuery(['my_orders'],
  () => getMyOrders(v10Client), {
    retryDelay: 5000,
    retryOnMount:false,
    retry:1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useMyOrderHook;
