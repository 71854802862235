import moment from 'moment';
import React from 'react';
import {
  CourseCardBody,
  CourseCardContainer,
  CourseCardName,
} from '../../../components/course/styles/FilteredCourseCardStyle';
import Swal from 'sweetalert2';
import { determineCredit } from '../../../common/utils';
import { catchUpClass } from '../../../faunadb/queries/student';
import { useFaunav10Context } from '../../../providers/FaunaV10Provider';

const catchUpPopUp = (
  client,
  fetch,
  c,
  price,
  creditLevel,
  handleClose,
  history
) => {
  const catch_up_cost_message = (price) => {
    if (creditLevel === 'catch_up_credit_tier_2')
      return 'and 🥇Gold Catch Up Point';
    if (creditLevel === 'catch_up_credit_tier_1')
      return 'and 🥈Silver Catch Up Point';
    if (creditLevel === 'catch_up_credit_tier_0')
      return 'and 🥉Bronze Catch Up Point';
    return '';
  };
  return Swal.queue([
    {
      title: `Catch up ${moment(c.date.isoString).format('MMM DD HH:mm')} -  ${c.course
        }?`,
      confirmButtonText: 'Catch up',
      showCancelButton: true,
      icon: 'question',
      text: `Cost \$${price} ${catch_up_cost_message(price)}`,
      showLoaderOnConfirmp: true,
      preConfirm: () => {
        return catchUpClass(client, c.ticket_id, price, creditLevel)
          .then((response) => {
            if (response.id) {
              Swal.fire({
                icon: 'success',
                title: ``,
                text: '',
              });
              fetch();
              handleClose();
            }
          }).catch((error) => {
            if (error) {
              if (error.abort.includes("in this class")) {
                Swal.fire({
                  icon: 'error',
                  title: error.abort,
                  text: '',
                  confirmButtonText: 'OK'
                });
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: error.abort,
                  text: '',
                  confirmButtonText: 'Top Up Now',
                  preConfirm: () => {
                    history.push('/me');
                  },
                });
              }
              fetch();
              handleClose();
            }
          });
      },
    },
  ]);
};

const CatchUpCard = ({
  c,
  fetch,
  catchUpCreditTier0,
  catchUpCreditTier1,
  catchUpCreditTier2,
  handleClose,
  history,
}) => {
  const {client} = useFaunav10Context();
  const creditLevel = determineCredit(moment(c.date.isoString), c, false);
  return (
    <>
      <CourseCardContainer>
        <div>
          <CourseCardBody>
            <CourseCardName>{c.course}</CourseCardName>
          </CourseCardBody>
          <CourseCardBody>{c?.teacher?.name}</CourseCardBody>
          <CourseCardBody>{c.classroom}</CourseCardBody>
          <CourseCardBody>
            {moment(c.date.isoString).format('MMM DD HH:mm')}
          </CourseCardBody>
          <div className="flex flex-col items-center justify-center">
            {creditLevel === 'catch_up_credit_tier_0' &&
              catchUpCreditTier0 > 0 && (
                <button
                  className="px-5 py-2 my-2 bg-green-500 hover:bg-green-600 rounded-full text-white disabled:opacity-50"
                  disabled={c.attended}
                  onClick={() =>
                    catchUpPopUp(
                      client,
                      fetch,
                      c,
                      0,
                      'catch_up_credit_tier_0',
                      handleClose,
                      history
                    )
                  }
                >
                  🥉 Free Catch up
                </button>
              )}
            {creditLevel === 'catch_up_credit_tier_0' &&
              catchUpCreditTier1 > 0 && (
                <button
                  className="px-5 py-2 my-2 bg-green-500 hover:bg-green-600 rounded-full text-white disabled:opacity-50"
                  disabled={c.attended}
                  onClick={() =>
                    catchUpPopUp(
                      client,
                      fetch,
                      c,
                      0,
                      'catch_up_credit_tier_1',
                      handleClose,
                      history
                    )
                  }
                >
                  🥈 Free Catch up
                </button>
              )}
            {creditLevel === 'catch_up_credit_tier_0' &&
              catchUpCreditTier2 > 0 && (
                <button
                  className="px-5 py-2 my-2 bg-green-500 hover:bg-green-600 rounded-full text-white disabled:opacity-50"
                  disabled={c.attended}
                  onClick={() =>
                    catchUpPopUp(
                      client,
                      fetch,
                      c,
                      0,
                      'catch_up_credit_tier_2',
                      handleClose,
                      history
                    )
                  }
                >
                  🥇 Free Catch up
                </button>
              )}

            {creditLevel === 'catch_up_credit_tier_1' &&
              catchUpCreditTier0 > 0 && (
                <button
                  className="px-5 py-2 my-2 bg-green-500 hover:bg-green-600 rounded-full text-white disabled:opacity-50"
                  disabled={c.attended}
                  onClick={() =>
                    catchUpPopUp(
                      client,
                      fetch,
                      c,
                      80,
                      'catch_up_credit_tier_0',
                      handleClose,
                      history
                    )
                  }
                >
                  🥉 & $80 Catch up
                </button>
              )}
            {creditLevel === 'catch_up_credit_tier_1' &&
              catchUpCreditTier1 > 0 && (
                <button
                  className="px-5 py-2 my-2 bg-green-500 hover:bg-green-600 rounded-full text-white disabled:opacity-50"
                  disabled={c.attended}
                  onClick={() =>
                    catchUpPopUp(
                      client,
                      fetch,
                      c,
                      0,
                      'catch_up_credit_tier_1',
                      handleClose,
                      history
                    )
                  }
                >
                  🥈 Free Catch up
                </button>
              )}
            {creditLevel === 'catch_up_credit_tier_1' &&
              catchUpCreditTier2 > 0 && (
                <button
                  className="px-5 py-2 my-2 bg-green-500 hover:bg-green-600 rounded-full text-white disabled:opacity-50"
                  disabled={c.attended}
                  onClick={() =>
                    catchUpPopUp(
                      client,
                      fetch,
                      c,
                      0,
                      'catch_up_credit_tier_2',
                      handleClose,
                      history
                    )
                  }
                >
                  🥇 Free Catch up
                </button>
              )}

            {creditLevel === 'catch_up_credit_tier_2' &&
              catchUpCreditTier0 > 0 && (
                <button
                  className="px-5 py-2 my-2 bg-green-500 hover:bg-green-600 rounded-full text-white disabled:opacity-50"
                  disabled={c.attended}
                  onClick={() =>
                    catchUpPopUp(
                      client,
                      fetch,
                      c,
                      150,
                      'catch_up_credit_tier_0',
                      handleClose,
                      history
                    )
                  }
                >
                  🥉 & $150 Catch up
                </button>
              )}
            {creditLevel === 'catch_up_credit_tier_2' &&
              catchUpCreditTier1 > 0 && (
                <button
                  className="px-5 py-2 my-2 bg-green-500 hover:bg-green-600 rounded-full text-white disabled:opacity-50"
                  disabled={c.attended}
                  onClick={() =>
                    catchUpPopUp(
                      client,
                      fetch,
                      c,
                      70,
                      'catch_up_credit_tier_1',
                      handleClose,
                      history
                    )
                  }
                >
                  🥈 & $70 Free Catch up
                </button>
              )}
            {creditLevel === 'catch_up_credit_tier_2' &&
              catchUpCreditTier2 > 0 && (
                <button
                  className="px-5 py-2 my-2 bg-green-500 hover:bg-green-600 rounded-full text-white disabled:opacity-50"
                  disabled={c.attended}
                  onClick={() =>
                    catchUpPopUp(
                      client,
                      fetch,
                      c,
                      0,
                      'catch_up_credit_tier_2',
                      handleClose,
                      history
                    )
                  }
                >
                  🥇 Free Catch up
                </button>
              )}
            <button
              className="px-5 py-2 my-2 bg-red-500 hover:bg-red-600 rounded-full text-white disabled:opacity-50"
              disabled={c.attended}
              onClick={() =>
                catchUpPopUp(
                  client,
                  fetch,
                  c,
                  350,
                  'catch_up_credit_tier_na',
                  handleClose,
                  history
                )
              }
            >
              $350 Drop in
            </button>
          </div>
        </div>
      </CourseCardContainer>
    </>
  );
};

export default CatchUpCard;
